<template>
	<div class="pa-4">
		<v-form
			id="signup-form"
			ref="signupForm"
			class="row ma-0"
			@submit.prevent="onSubmit"
		>
			<v-col class="px-0 pb-1" cols="12">
				<v-select
					v-model="state.selectedRole"
					:items="state.roles"
					:menu-props="{ offsetY: true }"
					:rules="state.roleRules"
					attach=""
					dense
					hide-details="auto"
					label="Roli"
					outlined
					prepend-inner-icon="mdi-account"
					validate-on-blur
					@change="state.errorMessage = ''"
				></v-select>
			</v-col>

			<v-col
				v-for="(field, index) in state.userFields"
				:key="`col-${index}`"
				:class="[
					$screen.width < 600 ? 'px-0' : '',
					index % 2 === 0 && $screen.width >= 600 ? 'pr-3' : '',
				]"
				:cols="12"
				:sm="field.colSize"
				class="px-0 pb-1"
			>
				<v-text-field
					:key="`field-${field.name}-${index}`"
					v-model="field.value"
					:autocomplete="field.type === 'password' ? 'on' : 'off'"
					:label="field.text"
					:prepend-inner-icon="field.icon"
					:rules="
						field.name !== 'passwordConfirm'
							? rules[field.rules]
							: passwordConfirmationRules
					"
					:type="field.type"
					dense
					hide-details="auto"
					outlined
					validate-on-blur
					@input="state.errorMessage = ''"
				></v-text-field>
			</v-col>

			<v-col class="mb-0 pb-1 px-0" cols="12">
				<v-checkbox
					v-model="state.acceptedTermsAndConditions"
					class="mt-0"
					hide-details="auto"
				>
					<template v-slot:label>
						<div @click.stop="">
							I pranoj
							<a href="#" @click.prevent="state.termsDialog = true"
								>kushtet e përdorimit</a
							>
						</div>
					</template>
				</v-checkbox>
			</v-col>

			<TermsAndConditions v-model="state.termsDialog" />

			<!--  Error msg  -->
			<Alert :msg="state.errorMessage" :show="state.showError">
				{{ state.errorMessage }}
			</Alert>

			<v-btn
				:disabled="!state.acceptedTermsAndConditions || state.loading"
				:loading="state.loading"
				class="full-width mt-3"
				color="primary"
				type="submit"
				depressed
			>
				Vazhdo
			</v-btn>
		</v-form>
	</div>
</template>

<script>
import { reactive } from "@vue/composition-api";

import { rules } from "../../utilities/form-validations.utilities";
import { displayErrorMessage } from "../../utilities/error-msg.utilities";
import Alert from "../general/Alert";
import TermsAndConditions from "../general/TermsAndConditions";

export default {
	name: "Register",
	components: { TermsAndConditions, Alert },
	props: ["loading"],
	setup: function (props, { root, refs, emit }) {
		const state = reactive({
			roles: [
				{
					value: "teacher",
					text: "Mësues",
				},
				{
					value: "student",
					text: "Nxënës",
				},
				{
					value: "parent",
					text: "Prind",
				},
				{
					value: "reader",
					text: "Lexues",
				},
			],
			roleRules: [(v) => !!v || "Duhet të zgjidhni rolin tuaj!"],
			selectedRole: null,

			userFields: [
				{
					name: "firstName",
					text: "Emri",
					type: "text",
					rules: "nameRules",
					icon: "mdi-format-letter-case",
					value: "",
					colSize: 6,
				},
				{
					name: "lastName",
					text: "Mbiemri",
					type: "text",
					rules: "lastNameRules",
					icon: "mdi-format-letter-case",
					value: "",
					colSize: 6,
				},
				{
					name: "fatherhood",
					text: "Atësia",
					type: "text",
					rules: "fatherNameRules",
					icon: "mdi-human-male-boy",
					value: "",
					colSize: 6,
				},
				{
					name: "email",
					text: "E-mail",
					type: "email",
					rules: "emailRules",
					icon: "mdi-email",
					value: "",
					colSize: 6,
				},
				{
					name: "school",
					text: "Shkolla",
					type: "text",
					rules: "schoolNameRules",
					icon: "mdi-school",
					value: "",
					colSize: 6,
				},
				{
					name: "city",
					text: "Qyteti / Fshati",
					type: "text",
					rules: "cityNameRules",
					icon: "mdi-city",
					value: "",
					colSize: 6,
				},
				{
					name: "password",
					text: "Fjalëkalimi",
					type: "password",
					rules: "passwordRules",
					icon: "mdi-lock",
					value: "",
					colSize: 6,
				},
				{
					name: "passwordConfirm",
					text: "Konfirmo fjalëkalimin",
					type: "password",
					rules: "passwordRules",
					icon: "mdi-lock",
					value: "",
					colSize: 6,
				},
			],

			// Terms and conditions
			termsDialog: false,
			acceptedTermsAndConditions: false,

			// Register
			loading: false,
			errorMessage: "",
			showError: false,
		});

		//  PW confirm rules
		let passwordConfirmationRules = [
			(v) => !!v || "Fjalëkalimi nuk mund të jetë bosh!",
			(v) =>
				v === state.userFields.find((f) => f.name === "password").value ||
				"Fjalëkalimi nuk është i njëjtë!",
		];

		function onSubmit() {
			if (!refs["signupForm"]?.validate()) return;

			let formData = {};

			// Add Keys
			const dataKeys = [];
			state.userFields.forEach((item) => {
				dataKeys.push(item.name);
			});

			// Setting role
			formData["role"] = state.selectedRole;

			// Mapping other fields
			for (let key of dataKeys) {
				formData[key] = state.userFields.find(
					(field) => field.name === key
				).value;
			}

			signUp(formData);
		}

		async function signUp(data) {
			try {
				state.loading = true;
				const response = await root.$store.dispatch("auth/signUp", data);

				if (response.status === 200) {
					root.$router.push("/classrooms");
				}
			} catch (err) {
				state.errorMessage = displayErrorMessage(err);
				state.showError = true;
			} finally {
				setTimeout(() => {
					state.loading = false;
				}, 300);
			}
		}

		return {
			state,
			rules,

			passwordConfirmationRules,
			onSubmit,
		};
	},
};
</script>

<style lang="scss" scoped>
.error-message {
	text-align: center;
	color: var(--v-error-base);
}

#signup-form::v-deep {
	.v-text-field__details {
		margin-bottom: 0 !important;
	}
}
</style>
