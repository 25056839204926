import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c(VForm,{ref:"signupForm",staticClass:"row ma-0",attrs:{"id":"signup-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c(VCol,{staticClass:"px-0 pb-1",attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.state.roles,"menu-props":{ offsetY: true },"rules":_vm.state.roleRules,"attach":"","dense":"","hide-details":"auto","label":"Roli","outlined":"","prepend-inner-icon":"mdi-account","validate-on-blur":""},on:{"change":function($event){_vm.state.errorMessage = ''}},model:{value:(_vm.state.selectedRole),callback:function ($$v) {_vm.$set(_vm.state, "selectedRole", $$v)},expression:"state.selectedRole"}})],1),_vm._l((_vm.state.userFields),function(field,index){return _c(VCol,{key:("col-" + index),staticClass:"px-0 pb-1",class:[
				_vm.$screen.width < 600 ? 'px-0' : '',
				index % 2 === 0 && _vm.$screen.width >= 600 ? 'pr-3' : '' ],attrs:{"cols":12,"sm":field.colSize}},[_c(VTextField,{key:("field-" + (field.name) + "-" + index),attrs:{"autocomplete":field.type === 'password' ? 'on' : 'off',"label":field.text,"prepend-inner-icon":field.icon,"rules":field.name !== 'passwordConfirm'
						? _vm.rules[field.rules]
						: _vm.passwordConfirmationRules,"type":field.type,"dense":"","hide-details":"auto","outlined":"","validate-on-blur":""},on:{"input":function($event){_vm.state.errorMessage = ''}},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)}),_c(VCol,{staticClass:"mb-0 pb-1 px-0",attrs:{"cols":"12"}},[_c(VCheckbox,{staticClass:"mt-0",attrs:{"hide-details":"auto"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" I pranoj "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.state.termsDialog = true}}},[_vm._v("kushtet e përdorimit")])])]},proxy:true}]),model:{value:(_vm.state.acceptedTermsAndConditions),callback:function ($$v) {_vm.$set(_vm.state, "acceptedTermsAndConditions", $$v)},expression:"state.acceptedTermsAndConditions"}})],1),_c('TermsAndConditions',{model:{value:(_vm.state.termsDialog),callback:function ($$v) {_vm.$set(_vm.state, "termsDialog", $$v)},expression:"state.termsDialog"}}),_c('Alert',{attrs:{"msg":_vm.state.errorMessage,"show":_vm.state.showError}},[_vm._v(" "+_vm._s(_vm.state.errorMessage)+" ")]),_c(VBtn,{staticClass:"full-width mt-3",attrs:{"disabled":!_vm.state.acceptedTermsAndConditions || _vm.state.loading,"loading":_vm.state.loading,"color":"primary","type":"submit","depressed":""}},[_vm._v(" Vazhdo ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }