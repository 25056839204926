<template>
	<!--    <h1 class="mb-8">Shkolla Virtuale</h1>-->

	<v-card
		class="bg-card ma-4 ma-auto"
		outlined
		max-width="600px"
		style="height: fit-content"
	>
		<v-btn-toggle
			v-model="state.mode"
			active-class="active"
			borderless
			class="full-width d-flex rounded-0"
			color="white"
			mandatory
		>
			<v-btn
				:disabled="state.loading && state.mode !== 0"
				style="width: 33%; background-color: var(--v-background-base)"
			>
				Hyr
			</v-btn>

			<v-btn
				:disabled="state.loading && state.mode === 0"
				style="width: 67%; background-color: var(--v-background-base)"
			>
				Regjistrohu
			</v-btn>
		</v-btn-toggle>
		<v-divider></v-divider>

		<!--  Login  -->
		<Login v-if="state.mode === 0" />

		<!--  Register  -->
		<Register v-else />
	</v-card>
</template>

<script>
import { reactive } from "@vue/composition-api";
import Login from "../components/login/Login";
import Register from "../components/login/Register";

export default {
	name: "LoginScreen",
	components: { Login, Register },

	setup(props, { root }) {
		const state = reactive({
			mode: 0,
		});

		return {
			state,
		};
	},
};
</script>

<style lang="scss" scoped>
.active {
	background-color: var(--v-primary-base) !important;
}
</style>
