<template>
	<div class="pa-4" style="max-width: 340px">
		<v-form
			id="login-form"
			ref="loginForm"
			class="row ma-0"
			@submit.prevent="onSubmit"
		>
			<v-col class="px-0 pt-0 pb-1" cols="12">
				<v-text-field
					v-model="state.email"
					:rules="rules.emailRules"
					dense
					hide-details="auto"
					label="E-mail"
					outlined
					prepend-inner-icon="mdi-email"
					type="email"
					validate-on-blur
					@input="state.showError = false"
				></v-text-field>
			</v-col>
			<v-col class="px-0 pb-1" cols="12">
				<v-text-field
					v-model="state.password"
					:append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="state.showPassword ? 'text' : 'password'"
					autocomplete="on"
					dense
					hide-details="auto"
					label="Fjalëkalimi"
					outlined
					prepend-inner-icon="mdi-lock"
					validate-on-blur
					@input="state.showError = false"
					@click:append="state.showPassword = !state.showPassword"
				></v-text-field>
			</v-col>

			<!--  Error msg  -->
			<Alert :msg="state.errorMessage" :show="state.showError">
				{{ state.errorMessage }}
			</Alert>

			<v-btn
				:disabled="state.loading"
				:loading="state.loading"
				class="full-width mt-3"
				color="primary"
				type="submit"
				depressed
			>
				Vazhdo
			</v-btn>
		</v-form>

		<div class="mt-3 text-center">
			<router-link to="/classrooms">Keni harruar fjalëkalimin?</router-link>
		</div>
	</div>
</template>

<script>
import { reactive } from "@vue/composition-api";

import { rules } from "../../utilities/form-validations.utilities";
import { displayErrorMessage } from "../../utilities/error-msg.utilities";
import Alert from "../general/Alert";
import UserService from "../../services/user.service";
import FlashService from "../../services/flash.service";

export default {
	name: "Login",
	components: { Alert },
	setup(props, { root, refs, emit }) {
		const state = reactive({
			email: null,
			showPassword: false,
			password: null,

			errorMessage: "",
			showError: false,

			loading: false,
		});

		function onSubmit() {
			if (!refs["loginForm"]?.validate()) return;

			logIn({
				email: state.email.trim().toLowerCase(),
				password: state.password,
			});
		}

		async function logIn(data) {
			try {
				state.loading = true;

				const response = await root.$store.dispatch("auth/logIn", data);

				if (response.status === 200) {
					// Fetch required data
					try {
						await UserService.fetchCurrentUserData();
						await root.$store.dispatch("classrooms/fetchClassrooms");
						await root.$store.dispatch("notifications/fetchNotifications");

						root.$router.push("/classrooms");
					} catch (err) {
						if (err.message === "NETWORK_ERROR") {
							FlashService.push({
								type: "error",
								msg: "Mungon lidhja me serverin!",
							});
						}
					}
				}
			} catch (err) {
				state.errorMessage = displayErrorMessage(err);
				state.password = null;
				state.showPassword = false;
				state.showError = true;
			} finally {
				setTimeout(() => {
					state.loading = false;
				}, 300);
			}
		}

		return {
			state,
			rules,
			onSubmit,
		};
	},
};
</script>

<style lang="scss" scoped>
.error-message {
	text-align: center;
	color: var(--v-error-base);
}

#login-form::v-deep {
	.v-text-field__details {
		margin-bottom: 0 !important;
	}
}
</style>
