<template>
	<ConfirmDialog
		v-model="showDialog"
		type="info"
		width="1000px"
		@closeDialog="showDialog = false"
	>
		<template v-slot:header>
			Kushtet e përdorimit
		</template>

		<template v-slot:content>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacus
			nibh, interdum sit amet dui at, luctus vestibulum sapien. Cras quis augue
			cursus arcu hendrerit scelerisque ac id massa. Duis sit amet faucibus mi.
			Nunc condimentum nibh ac velit pellentesque, sed efficitur nulla mattis.
			Vivamus ornare sagittis elit vel faucibus. Suspendisse potenti. Curabitur
			et tortor eget augue rhoncus consectetur.
			<br /><br />
			Sed sollicitudin varius metus, elementum imperdiet ante volutpat et.
			Phasellus leo nisi, facilisis nec congue ac, imperdiet vitae diam.
			Praesent dignissim tincidunt dui, ut tincidunt felis. Phasellus ut massa
			vel dolor accumsan commodo. Nullam tempus lectus eget imperdiet feugiat.
			Curabitur a placerat nibh. Nunc id mi erat. Integer sit amet nulla risus.
			Curabitur porttitor posuere mi, a porttitor felis. Aliquam erat volutpat.
			Cras cursus ipsum ut erat finibus lacinia.
			<br /><br />
			Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer eget
			blandit augue, non feugiat tellus. Maecenas in nulla rutrum est malesuada
			tincidunt sed vel purus. Pellentesque congue est vitae mauris lacinia,
			pulvinar volutpat massa sagittis. Aenean nec purus mi. Fusce quis auctor
			ipsum, sit amet fermentum felis. Ut mauris tellus, ultrices nec urna eget,
			elementum sagittis arcu. Nullam sit amet convallis lorem. In nec odio
			dictum, accumsan ex imperdiet, dapibus libero. Sed at lacinia nisl. Nulla
			ullamcorper sodales massa, a convallis lacus sagittis non. Maecenas metus
			est, laoreet eu augue id, lobortis volutpat eros.
			<br /><br />
			Aenean sollicitudin elit cursus neque consectetur, nec tempus erat
			gravida. Maecenas aliquam sem congue vehicula pellentesque. Praesent
			congue, augue a molestie laoreet, ligula quam venenatis metus, quis
			ultrices enim odio a libero. Curabitur ornare elit diam, vulputate cursus
			justo posuere ut. Ut at sagittis tellus. Donec at velit ut nunc varius
			tincidunt eget eu lacus. Pellentesque habitant morbi tristique senectus et
			netus et malesuada fames ac turpis egestas. Etiam vel eros sed dolor
			sollicitudin sodales. Suspendisse placerat tellus risus, in feugiat leo
			aliquam vitae. Sed cursus egestas imperdiet. Sed vehicula urna quis dictum
			lacinia.
			<br /><br />
			Vestibulum dignissim accumsan ipsum eget sodales. Orci varius natoque
			penatibus et magnis dis parturient montes, nascetur ridiculus mus.
			Maecenas volutpat rhoncus nunc a pulvinar. Duis blandit et justo ac
			volutpat. Morbi vitae lobortis libero. Morbi ac dignissim leo, vel
			eleifend quam. Maecenas ut urna quam. Pellentesque accumsan maximus
			sodales. Nunc efficitur turpis sed metus tincidunt pharetra. In eget
			ligula nisl. Proin convallis elit metus, quis varius est bibendum eu.
			Mauris rutrum neque in augue varius semper. Proin in tortor tortor. Ut
			venenatis enim eu lorem placerat, eu iaculis dui porta.
		</template>

		<template v-slot:actions>
			<v-spacer />
			<v-btn color="primary" @click="showDialog = false">
				OK
			</v-btn>
		</template>
	</ConfirmDialog>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog";
import { computed, reactive } from "@vue/composition-api";

export default {
	name: "TermsAndConditions",
	components: { ConfirmDialog },
	props: ["value"],
	setup(props, { root, refs, emit }) {
		const state = reactive({});

		const showDialog = computed({
			get: () => props.value,
			set: value => {
				emit("input", value);
			}
		});

		return {
			state,
			showDialog
		};
	}
};
</script>

<style scoped></style>
